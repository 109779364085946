<template>
  <v-container fluid>
    <v-sheet rounded>
      <v-row>
        <v-col cols="12" sm="6">
          <p class="caption ma-0 pl-4">{{$_strings.invoice.INVOICE_NO}} : {{ $route.query.invoiceNo }}</p>
          <p
            class="caption ma-0 pl-4"
          >
            {{$_strings.invoice.ORDER_PERIODE}} : {{$route.query.invoiceGroup}} {{ $route.query.startDate }} s/d {{$route.query.endDate}}
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-end"
        >
          <v-btn
            color="primary"
            small
            @click="updateInvoice"
          >
            {{$_strings.invoice.TITLE_EDIT}}
          </v-btn>
          <v-btn
            small
            color="primary"
            outlined
            class="ml-2"
            @click="$router.go(-1)"
          >
            {{$_strings.common.BACK}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="itemsInvoice"
            item-key="limitId"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            :loading="isLoading"
            :loading-text="$_strings.order.LOADING_TEXT"
          >
            <template v-slot:[`item.qty`]={item}>
              <v-text-field
                min="0"
                v-mask="{'alias': 'custom-integer'}"
                step="1"
                outlined
                dense
                class="caption pt-2"
                v-model="item.qty"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.priceUnit`]={item}>
              <common-text-field-currency
                dense
                outlined
                prefix="Rp"
                class="body-2 pt-2"
                v-model="item.priceUnit"
              />
            </template>
            <template v-slot:[`item.totalPrice`]={item}>
              <div class="text-center">
                <p class="mb-0">{{ formatAsCurrency(item.totalPrice) }}</p>
                <p class="caption">
                  {{ item.costName }}
                </p>
              </div>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  renamePeriode,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  getDate,
} from '@/helper/commonHelpers';

export default {
  name: 'invoice-fixed-price-list-price-detail',
  data() {
    return {
      headers: [
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentTitle',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.TRANSPORTER_NAME,
          value: 'companyAbbreviation',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.TRANSPORT_TYPE,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.QUANTITY,
          value: 'qty',
          class: 'white--text primary text-capitalize',
          width: '180px',
        },
        {
          text: this.$_strings.invoice.UNIT_PRICE,
          value: 'priceUnit',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'totalPrice',
          class: 'white--text primary text-capitalize',
          width: '250px',
        },
      ],
      invoiceDetail: {},
      itemsInvoice: [],
      invoiceListTotalEntry: 0,
      pagination: defaultPagination(),
      isLoading: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchInvoice();
      },
      deep: true,
    },
  },
  methods: {
    renamePeriode,
    getDate,
    async fetchInvoice() {
      this.expanded = [];
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      try {
        const { invoiceId } = this.$route.params;
        this.isLoading = true;
        const result = await this.$_services.invoice.listDetailFixedPriceDetailOrderInvoice(filters, invoiceId);
        this.itemsInvoice = result.data.contents;
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async updateInvoice() {
      const form = this.itemsInvoice.map((item) => ({
        invoiceId: item.invId,
        priceUnit: +item.priceUnit,
        qty: +item.qty,
      }));
      try {
        this.$root.$loading.show();
        this.$_services.invoice.updateFixedPriceInvoice(form);
        this.$dialog.notify.success(this.$_strings.invoice.EDIT_MESSAGE_TEXT);
      } finally {
        this.$root.$loading.hide();
      }
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
  },
};
</script>
